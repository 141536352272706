<template>
  <v-list class="mt-5">
    <v-list-group
      v-for="filter in getFilters"
      :key="filter.code"
      no-action
      sub-group
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>{{ filter.name }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item
        v-for="filterable in filter.filterables"
        :key="filterable.code"
      >
        <v-checkbox
          :label="filterable.name"
          :value="filterable.code"
          v-model="selected"
          :id="`${filter.code}_${filterable.code}`"
          @change="eChangeChk($event, filter.code)"
          class="shrink mr-0 mt-0"
        ></v-checkbox>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      selected: []
    };
  },
  computed: {
    ...mapGetters(["getFilters"])
  },
  methods: {
    ...mapActions(["composeFilterArr"]),
    eChangeChk: function(event, filter_code) {
      const filter = {
        code: filter_code,
        values: event
      };

      this.composeFilterArr(filter);
    }
  }
};
</script>

<style scoped></style>
