import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#db9f2b",
        secondary: "#46301b",
        accent: "#eeeeee",
        error: "#b71c1c",
        light_brown: "#a77d53"
      }
    },
    options: {
      customProperties: true
    }
  }
});
