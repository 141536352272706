<template>
  <v-footer dark class="accent paws-footer mt-16">
    <v-row class="mt-1">
      <v-col :cols="isMobile ? '12' : '1'">
        <v-img
          :src="require('../../assets/paws-logo.svg')"
          :width="isMobile ? '50%' : undefined"
          transition="scale-transition"
          contain
          class="logo-img"
          @click="goToHomePage()"
        ></v-img>
      </v-col>
      <v-col>
        <p class="black--text">
          Paws&Belly<br />
          Bredestraat 156 <br />
          9920 Lievegem <br />
          België <br />
          BE 0761 367 252
          <br /><br />
          <a href="mailto:info@pawsandbelly.com">info@pawsandbelly.com</a>
          <br />
          <span class="text-caption"
            ><v-icon color="black" small>mdi-copyright</v-icon> Alle rechten
            voorbehouden | Paws&Belly</span
          >
        </p>
      </v-col>
      <v-col :cols="isMobile ? '12' : 'auto'">
        <p>
          <router-link class="black--text" to="/terms_and_conditions"
            >Algemene voorwaarden</router-link
          >
          <br />
          <router-link class="black--text" to="/delivery_conditions"
            >Levervoorwaarden</router-link
          >
        </p>
      </v-col>
      <v-col :cols="isMobile ? '12' : '2'"
        ><v-btn
          v-for="icon in icons"
          :key="icon.id"
          icon
          class="mx-4"
          color="primary"
          :href="icon.link"
          target="_blank"
        >
          <v-icon color="secondary">{{ icon.icon }}</v-icon>
        </v-btn></v-col
      >
    </v-row>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    icons: [
      { id: 1, icon: "mdi-facebook", link: "http://facebook.com/pawsandbelly" },
      {
        id: 2,
        icon: "mdi-instagram",
        link: "https://instagram.com/paws_and_belly"
      }
    ]
  }),
  computed: {
    ...mapGetters(["isMobile"]),
    imgWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 125;
        case "sm":
          return 175;
        case "md":
          return 175;
        default:
          return 220;
      }
    },
    extensionHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return 25;
        case "xl":
          return 1;
        default:
          return 0;
      }
    }
  },
  methods: {
    goToHomePage: function() {
      if (this.$route.name !== "home") {
        this.$router.push("/");
      }
    }
  }
};
</script>

<style scoped>
.brand-name {
  color: var(--v-secondary-base);
}

.brand-name a {
  color: var(--v-secondary-base);
}
.paws-footer {
  z-index: 9000;
}
</style>
