<template>
  <v-container
    style="margin-top: 3rem;"
    class="text-body-2"
    @contextmenu.prevent.stop
  >
    <h3>Verzending & levertijden</h3>
    <br />
    <h4>1. VERZENDING</h4>
    <p>
      Voorlopig leveren we enkel in België en Nederland.<br />
      Voor leveringen voorzien we standaard verzending via DPD of B-post € 6,-
      verzendkosten. Gratis levering vanaf € 85,- <br />
      Na ontvangst van uw betaling bieden we het pakket zo snel mogelijk (2 tot
      4 werkdagen later) aan bij de koerierdienst.
    </p>
    <h4>
      2. AFHALING
    </h4>
    <p>
      Voor afhaling kan je terecht bij ons na een bericht dat jouw bestelling
      klaar staat en wanneer je deze kan op halen.<br />
      Adres: <br />Bredestraat 156<br />
      9920 Lievegem<br />
      0472/177 496
    </p>
    <h4>3. MAANDELIJKSE LEVERINGEN</h4>
    <p>
      Elke 2de en laatste vrijdag van de maand komen we persoonlijk leveren.
      Voorlopig is dit enkel beschikbaar in regio Evergem / Lievegem.<br />
      Geef je bestelling 8 werkdagen op voorhand door en we verzorgen jouw
      levering de eerst komende vrijdag (2de of laatste van de maand). Minimum
      bestelwaarde € 55,- <br />
      Als je voor deze optie kiest krijg je een gratis leuke attentie voor je
      viervoeter.
    </p>
    <iframe
      src="https://www.google.com/maps/d/embed?mid=1W2fWOWB5S_TtH7FJX6z0n1RXq9EfqTQ&ehbc=2E312F"
      :width="isMobile ? '380' : '800'"
      height="600"
      style="border:0"
      allowfullscreen
      loading="lazy"
    ></iframe>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["isMobile"])
  }
};
</script>

<style scoped></style>
