<template>
  <v-app>
    <the-header></the-header>
    <v-main>
      <v-alert
        border="left"
        dismissible
        color="success"
        icon="mdi-cart-outline"
        :value="isOpened"
        transition="slide-y-transition"
        class="alert-msg"
        elevation="3"
        >Het artikel is toegevoegd aan je winkelmand</v-alert
      >
      <!-- <v-container>
        <v-card>
          <v-card-text class="text-center text-h5"
            >Deze webshop is nog steeds in opbouw. Wij aanvaarden dus nog geen
            bestellingen</v-card-text
          >
        </v-card></v-container
      > -->
      <!-- <the-alert
        :show="true"
        :text="
          'Wij zijn even in verlof. Alle bestellingen die na 27/07 geplaatst zijn zullen pas na 08/08 worden verwerkt.'
        "
      ></the-alert> -->
      <router-view transition="slide-x-transition"></router-view>
      <promo v-if="isHomePage()"></promo>
      <v-fab-transition>
        <v-btn
          fab
          right
          bottom
          color="primary"
          fixed
          class="hidden-lg-and-up"
          @click.stop="mobileDrawer = !mobileDrawer"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-navigation-drawer v-model="mobileDrawer" fixed temporary bottom>
        <v-list>
          <v-list-group prepend-icon="mdi-menu" no-action :value="true">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Menu</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="menuTab in getMenu">
              <v-list-item
                :key="menuTab.id"
                :to="tabLink(menuTab)"
                link
                exact
                v-if="!isFolder(menuTab)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="menuTab.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-group
                :key="menuTab.id"
                v-if="isFolder(menuTab)"
                no-action
                sub-group
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="menuTab.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="child in menuTab.children"
                  :key="child.id"
                  :to="tabLink(child)"
                  exact
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="child.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </template>
          </v-list-group>
          <v-list-group
            prepend-icon="mdi-filter"
            no-action
            v-if="$route.name === 'product-list'"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Filters</v-list-item-title>
              </v-list-item-content>
            </template>
            <filter-list></filter-list>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </v-main>
    <the-footer></the-footer>
    <the-cookie-banner></the-cookie-banner>
  </v-app>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import TheHeader from "./components/UI/TheHeader.vue";
import TheFooter from "./components/UI/TheFooter.vue";
import FilterList from "./components/products/FilterList.vue";
import Promo from "./components/Promo.vue";
import TheCookieBanner from "./components/UI/TheCookieBanner.vue";
// import TheAlert from "./components/UI/TheAlert.vue";
export default {
  data() {
    return {
      mobileDrawer: false,
      currentTab: null
    };
  },
  components: {
    TheHeader,
    TheFooter,
    FilterList,
    Promo,
    TheCookieBanner
    // TheAlert
  },
  metaInfo: {
    title: "Home",
    titleTemplate: "%s | Paws&Belly"
  },
  methods: {
    ...mapMutations(["setMobile"]),
    tabLink: function(tab) {
      return `/products?category=${tab.code}`;
    },
    isFolder: function(menuItem) {
      if (menuItem.children) {
        if (menuItem.children.length !== 0) {
          return true;
        }
      }
      return false;
    },
    isHomePage: function() {
      if (this.$route.name === "home") return true;
      return false;
    }
  },
  computed: {
    ...mapGetters(["isOpened", "isMobile", "getMenu"])
  },
  mounted() {
    if (screen.width > 700) {
      this.setMobile(false);
    } else {
      this.setMobile(true);
    }
  }
};
</script>

<style>
.primary {
  color: var(--v-secondary-base) !important;
}

.v-main .alert-msg {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 30rem;
  z-index: 900;
}

.v-application p {
  word-break: normal;
}

.col .v-card .v-card__text.delivery-notice {
  color: var(--v-secondary-base);
  background-color: var(--v-primary-base);
  font-weight: bold;
}
.logo-img {
  cursor: pointer;
}
</style>
