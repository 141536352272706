import api from "./../../api";
const state = {
  products: []
};

const getters = {
  allProducts: (state) => state.products,
  productInfo: (state) => {
    return (keyword) => {
      return state.products.filter((product) => product.code === keyword)[0];
    };
  },
  defaultOption: (state, getters) => {
    return (prodCode) => {
      return getters
        .productInfo(prodCode)
        .options.filter((option) => option.default === true)[0];
    };
  },
  filteredProducts: (state) => {
    return (filterArr) => {
      const filtered = [];
      let found = [];

      filterArr.forEach((el) => {
        switch (el.code) {
          case "PROTEIN_SOURCES":
            if (found.length === 0) {
              found = state.products.filter((product) => {
                return product[el.code.toLowerCase()].some((f) => {
                  return el.values.includes(f);
                });
              });
            }
            break;

          default:
            break;
        }

        found.forEach((a) => {
          if (filtered.indexOf(a) === -1) {
            filtered.push(a);
          }
        });
      });
      if (filtered.length !== 0) {
        return filtered;
      } else if (filterArr !== 0) {
        return state.products;
      }
    };
  },
  productsAreDeliverable: (state) => {
    return !state.products.some(
      (product) => product.category.deliverable === false
    );
  }
};

const actions = {
  fetchProducts: (context, category) => {
    return new Promise((resolve) => {
      context.commit("toggleLoader");

      api
        .fetchProducts(category)
        .then((response) => {
          if (response.data.success) {
            context.commit("setProducts", response.data.products);
            if (response.data.products.length !== 0) {
              context.commit("toggleLoader");
            }
            resolve({ success: true, errors: [] });
          } else {
            resolve({ success: false, errors: [response.data.message] });
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          resolve({ success: false, errors: [error] });
        });
    });
  },
  fetchProduct: (context, product_code) => {
    return new Promise((resolve) => {
      api
        .fetchProduct(product_code)
        .then((response) => {
          if (response.data.success) {
            context.commit("setProducts", response.data.products);
            resolve({ success: true, errors: [] });
          } else {
            resolve({ success: false, errors: [response.data.message] });
          }
        })
        .catch((error) => {
          resolve({ success: false, errors: [error] });
        });
    });
  }
};

const mutations = {
  setProducts: (state, products) => {
    state.products = products;
  }
};

export default { state, getters, actions, mutations };
