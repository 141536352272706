<template>
  <v-badge
    overlap
    offset-y="20"
    offset-x="20"
    :content="cartCount"
    :value="cartCount"
    color="secondary"
  >
    <v-btn color="primary" fab to="/checkout" class="mr-2" :small="isMobile">
      <v-icon :small="isMobile">mdi-cart-outline</v-icon></v-btn
    ></v-badge
  >
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      menuOpen: false
    };
  },
  computed: {
    ...mapGetters(["cartCount", "cartContents", "subTotal", "isMobile"])
  },
  methods: {
    ...mapActions(["removeFromCart", "increaseAmount", "decreaseAmount"]),
    toCheckout: function() {
      this.menuOpen = false;
      this.$router.push({ name: "checkout" });
    }
  }
};
</script>
<style scoped>
.cart-product-amount {
  margin-left: auto;
}
.cart-product-remove {
  margin-right: 1rem;
}

.cart-total span:last-child {
  margin-left: auto;
}
</style>
