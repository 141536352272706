const state = {
  loaded: true
};

const getters = {
  isLoaded: (state) => state.loaded
};

const actions = {};

const mutations = {
  toggleLoader: (state) => {
    state.loaded = !state.loaded;
  }
};

export default { state, getters, actions, mutations };
