<template>
  <v-snackbar
    v-model="cookieBanner"
    timeout="-1"
    vertical
    style="z-index: 999999"
    v-show="false"
  >
    Staat u ons toe cookies te gebruiken?
    <template v-slot:action="{ attrs }">
      <v-btn color="primary" v-bind="attrs" @click="enableTracking">Ja</v-btn>
      <v-btn
        @click="disableTracking"
        v-bind="attrs"
        class="ml-1"
        color="light_brown"
        >Liever niet</v-btn
      >
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      cookieBanner: true
    };
  },
  methods: {
    disableTracking: function() {
      this.$gtag.optOut();
      this.cookieBanner = false;
    },
    enableTracking: function() {
      this.$gtag.optIn();
      this.cookieBanner = false;
    }
  }
};
</script>

<style scoped></style>
