import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";

// import modules
import ShoppingCart from "./modules/ShoppingCart";
import Categories from "./modules/Categories";
import Products from "./modules/Products";
import Loader from "./modules/Loader";
import Alert from "./modules/Alert";
import Filters from "./modules/Filters";
import UIElements from "./modules/UIElements";
import Configs from "./modules/Configs";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ShoppingCart,
    Categories,
    Products,
    Loader,
    Alert,
    Filters,
    UIElements,
    Configs
  },
  plugins: [
    createPersistedState({
      paths: ["ShoppingCart", "Configs"],
      key: "Paws&Belly"
    })
  ]
});
