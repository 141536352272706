<template>
  <div class="d-flex justify-center flex-wrap" id="cards-wrapper">
    <v-card
      v-for="category in getCategories($route.query.parent)"
      :key="category.id"
      :max-width="width"
      class="categories"
      raised
      outlined
      :to="cardLink(category)"
    >
      <v-img
        :src="
          `https://pawsandbelly.com${category.picture.location}/${category.picture.filename}`
        "
        :height="height"
      ></v-img>
      <v-card-title
        v-text="category.name"
        class="text-subtitle-1 text-md-h5 text-center"
      ></v-card-title>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import utils from "../utils";
export default {
  computed: {
    ...mapGetters(["getCategories"]),
    height() {
      switch (this.$vuetify.breakpoint.name) {
        // case "xs":
        //   return 100;
        case "sm":
          return 150;
        case "md":
          return 50;
        case "lg":
          return 150;
        default:
          return 200;
      }
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return 350;
        case "sm":
          return 200;
        case "md":
          return 200;
        case "lg":
          return 250;
        // case "xs":
        //   return 150;
        default:
          return 350;
      }
    }
  },
  methods: {
    ...mapActions(["fetchCategories"]),
    cardLink: function(category) {
      if (category.children) {
        return `/categories?parent=${category.code}`;
      } else {
        return `/products?category=${category.code}`;
      }
    }
  },
  metaInfo() {
    return {
      title: utils.initcap(this.$route.query.parent) || "Home"
    };
  },
  mounted() {
    console.log("Category list mounted");
  }
};
</script>

<style scoped>
.categories {
  margin: 2%;
}
</style>
