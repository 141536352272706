import utils from "./../../utils";
const state = {
  cart: []
};

const getters = {
  cartCount: (state) => {
    let total = 0;
    state.cart.forEach((el) => {
      total += el.amount;
    });
    return total;
  },
  cartIsEmpty: (state, getters) => {
    if (getters.cartCount !== 0) {
      return true;
    } else {
      return false;
    }
  },
  cartContents: (state) => {
    return state.cart;
  },
  deliveryPrice: (state, getters, rootState, rootGetters) => {
    return (delivery_type) => {
      if (delivery_type === "PICKUP") {
        return 0;
      } else {
        const free_delivery_amount = parseFloat(
          rootGetters.getParameterVal("FREE_DELIVERY_AMOUNT")
        );

        let delivery_fee;

        if (getters.subTotal >= free_delivery_amount) {
          delivery_fee = 0;
        } else {
          delivery_fee = parseFloat(
            rootGetters.getParameterVal("DELIVERY_FEE")
          );
        }

        return delivery_fee;
      }
    };
  },
  subTotal: (state) => {
    let total = 0;
    console.log("sub total");
    state.cart.forEach((item) => {
      console.log(item);
      total +=
        item.amount * (item.option.unit_price * ((100 - item.discount) / 100));
    });
    return utils.formatNumber(total);
  },
  totalPrice: (state, getters) => {
    return (delivery_type) => {
      return utils.formatNumber(
        parseFloat(getters.subTotal) +
          parseFloat(getters.deliveryPrice(delivery_type))
      );
    };
  },
  notDeliverable: (state) => {
    return state.cart.some((item) => item.deliverable === false);
  }
};

const actions = {
  addToCart: (context, { product, optionId, variation }) => {
    context.commit("addToCart", { product, optionId, variation });
    context.commit("toggleAlert");

    setTimeout(() => {
      context.commit("toggleAlert");
    }, 3000);
  },
  removeFromCart: (context, prodIndex) => {
    context.commit("removeFromCart", prodIndex);
  },
  increaseAmount: (context, index) => {
    context.commit("increaseAmount", index);
  },
  decreaseAmount: (context, index) => {
    context.commit("decreaseAmount", index);
  },
  clearCart: (context) => {
    context.commit("clearCart");
  }
};

const mutations = {
  addToCart: (state, { product, optionId, variation }) => {
    // compose the cartItem
    const cartItem = {
      code: product.code,
      discount: product.discount,
      name: product.name,
      picture: product.picture,
      deliverable: product.category.deliverable
    };

    cartItem.option = product.options.filter((el) => el.id === optionId)[0];
    // check is already added to the cart
    let productFound = false;
    state.cart = state.cart.map((el) => {
      if (
        el.code === cartItem.code &&
        el.option.id === cartItem.option.id &&
        el.option.variation === variation
      ) {
        el.amount++;
        productFound = true;
      }
      return el;
    });

    if (!productFound) {
      if (variation) {
        cartItem.option.variation = variation;
      }
      cartItem.amount = 1;
      state.cart.push(cartItem);
    }
  },
  removeFromCart: (state, prodIndex) => {
    state.cart.splice(prodIndex, 1);
  },
  increaseAmount: (state, index) => {
    state.cart[index].amount += 1;
  },
  decreaseAmount: (state, index) => {
    state.cart[index].amount -= 1;
    if (state.cart[index].amount === 0) {
      state.cart.splice(index, 1);
    }
  },
  clearCart: (state) => {
    state.cart = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
