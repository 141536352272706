<template>
  <v-container>
    <v-row>
      <v-col>
        <the-alert
          :show="order.delivery_type === 'PICKUP' && !notDeliverable"
          text="U kan bij ons terecht voor afhaling op maandag en donderdag van
              18u00 tot 20u00"
        >
        </the-alert>
        <the-alert
          :show="notDeliverable"
          text="Één van je producten is enkel geschikt voor afhaling. Je kan hiervoor bij ons terecht op maandag en donderdag van 18u00 tot 20u00"
        ></the-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        :cols="`${isMobile ? '12' : '8'}`"
        :style="`${isMobile ? 'order: 2;' : ''}`"
      >
        <v-card>
          <v-card-title>Bestelgegevens</v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="billing" lazy-validation aria-autocomplete="both">
                <v-row>
                  <v-col>
                    <v-text-field
                      autocomplete="given-name"
                      label="Voornaam"
                      v-model="order.billing_addr.first_name"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Naam"
                      autocomplete="family-name"
                      v-model="order.billing_addr.last_name"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="`${isMobile ? '6' : '3'}`">
                    <v-text-field
                      autocomplete="address-line1"
                      label="Straatnaam"
                      v-model="order.billing_addr.street"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="`${isMobile ? '6' : '3'}`">
                    <v-text-field
                      autocomplete="address-line2"
                      label="Huisnummer"
                      v-model="order.billing_addr.house_nr"
                      :rules="[rules.required]"
                      pattern="[0-9]*"
                      inputmode="numeric"
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="`${isMobile ? '6' : '3'}`">
                    <v-text-field
                      autocomplete="postal-code"
                      label="Postcode"
                      v-model="order.billing_addr.zip_code"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="`${isMobile ? '6' : '3'}`">
                    <v-text-field
                      autocomplete="address-level2"
                      label="Gemeente"
                      v-model="order.billing_addr.city"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      autocomplete="tel"
                      label="Telefoonnummer"
                      v-model="order.telephone_nr"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="E-mailadres"
                      :rules="[rules.required, rules.email]"
                      v-model="order.email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      :items="delivery_types"
                      v-model="order.delivery_type"
                      label="Leveringswijze"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-switch
                      v-model="order.newsletter"
                      label="Nieuwsbrief"
                    ></v-switch>
                  </v-col>
                  <v-col>
                    <v-switch :rules="[rules.required]">
                      <template v-slot:label>
                        <router-link
                          to="/terms_and_conditions"
                          style="color: rgba(0, 0, 0, 0.6);"
                          >Algemene voorwaarden</router-link
                        >
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-container>
              <v-row>
                <v-col>
                  <v-switch label="Factuur" v-model="order.invoice"></v-switch>
                </v-col>
              </v-row>
            </v-container>
            <v-expand-transition>
              <v-container v-show="order.invoice">
                <v-form lazy-validation ref="invoice_info">
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="BTW nr"
                        :rules="[rules.required]"
                        v-model="order.vat_nr"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Bedrijfsnaam"
                        :rules="[rules.required]"
                        v-model="order.company_name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-switch
                        label="Bezorggevens"
                        v-model="order.delivery_info"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <v-container v-show="order.delivery_info">
                      <v-divider></v-divider>
                      <v-form ref="delivery" lazy-validation>
                        <v-row>
                          <v-col>
                            <v-text-field
                              label="Voornaam"
                              v-model="order.delivery_addr.first_name"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              label="Naam"
                              v-model="order.delivery_addr.last_name"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col :cols="`${isMobile ? '6' : '3'}`">
                            <v-text-field
                              label="Straatsnaam"
                              v-model="order.delivery_addr.street"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col :cols="`${isMobile ? '6' : '3'}`">
                            <v-text-field
                              label="Huisnummer"
                              v-model="order.delivery_addr.house_nr"
                              :rules="[rules.required]"
                              pattern="[0-9]*"
                              inputmode="numeric"
                            ></v-text-field>
                          </v-col>
                          <v-col :cols="`${isMobile ? '6' : '3'}`">
                            <v-text-field
                              label="Postcode"
                              v-model="order.delivery_addr.zip_code"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col :cols="`${isMobile ? '6' : '3'}`">
                            <v-text-field
                              label="Gemeente"
                              v-model="order.delivery_addr.city"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container> </v-expand-transition
                ></v-form>
              </v-container>
            </v-expand-transition>
          </v-card-text>
        </v-card>
        <v-card v-if="isMobile" style="margin-top: 1rem;">
          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr>
                  <td class="text-left">Verzendkosten</td>
                  <td class="text-right font-weight-bold">
                    {{
                      `${
                        deliveryPrice(order.delivery_type) == 0
                          ? "Gratis"
                          : "€ " + deliveryPrice(order.delivery_type)
                      }`
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Totaalbedrag</td>
                  <td class="text-right font-weight-bold">
                    € {{ totalPrice(order.delivery_type) }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn block color="primary" @click.prevent="place_order">
                  Betalen
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card></v-col
      ><v-col
        :cols="`${isMobile ? '12' : '4'}`"
        :style="`${isMobile ? 'order: 1;' : 'position: fixed; right: 0;'}`"
        ><v-card style="margin-bottom: 1rem;">
          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr>
                  <td class="text-left">Verzendkosten</td>
                  <td class="text-right font-weight-bold">
                    {{
                      `${
                        deliveryPrice(order.delivery_type) == 0
                          ? "Gratis"
                          : "€ " + deliveryPrice(order.delivery_type)
                      }`
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Totaalbedrag</td>
                  <td class="text-right font-weight-bold">
                    € {{ totalPrice(order.delivery_type) }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn block color="primary" @click.prevent="place_order">
                  Betalen
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-card :style="isMobile ? '' : 'overflow-x: auto; height: 350px;'">
          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr
                  v-for="(item, index) in cartContents"
                  :key="`cart-${index}`"
                >
                  <td class="text-left">
                    {{ item.name }} <br />
                    ( {{ item.option.description }}
                    {{
                      `${
                        item.option.variation
                          ? " - " + item.option.variation.name ||
                            item.option.variation
                          : ""
                      }`
                    }}
                    )
                  </td>
                  <td>x {{ item.amount }}</td>
                  <td>=</td>
                  <td class="text-right">
                    €
                    {{
                      itemPrice(
                        item.option.unit_price * ((100 - item.discount) / 100),
                        item.amount
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "./../api";
import TheAlert from "./UI/TheAlert.vue";
import utils from "./../utils/index.js";

export default {
  data() {
    return {
      delivery_types: [
        { text: "Levering", value: "DELIVERY" },
        { text: "Afhalen", value: "PICKUP" },
        { text: "Maandelijkse levering", value: "MONTHLY" }
      ],
      rules: {
        email: v => /.+@.+\..+/.test(v) || "This isn't a valid email",
        required: value => !!value || "This field is required"
      },
      order: {
        delivery_type: "DELIVERY",
        newsletter: true,
        delivery_info: false,
        email: "",
        telephone_nr: "",
        invoice: false,
        vat_nr: "",
        company_name: "",
        delivery_addr: {
          first_name: "",
          last_name: "",
          street: "",
          house_nr: "",
          zip_code: "",
          city: ""
        },
        billing_addr: {
          first_name: "",
          last_name: "",
          street: "",
          house_nr: "",
          zip_code: "",
          city: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters([
      "cartCount",
      "cartContents",
      "totalPrice",
      "notDeliverable",
      "isMobile",
      "deliveryPrice"
    ])
  },
  components: {
    TheAlert
  },
  metaInfo() {
    return {
      title: "Bestelgegevens"
    };
  },
  methods: {
    ...mapActions(["clearCart"]),
    itemPrice: function(unit_price, amount) {
      return utils.formatNumber(unit_price * amount);
    },
    validateForms: function() {
      if (this.$refs.billing.validate()) {
        if (
          !this.order.invoice ||
          (this.order.invoice && this.$refs.invoice_info.validate())
        ) {
          if (
            !(
              (this.order.delivery_info && this.$refs.delivery.validate()) ||
              !this.order.delivery_info
            )
          ) {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }

      return true;
    },
    place_order: function() {
      if (this.validateForms()) {
        api.postOrder(this.order, this.cartContents).then(response => {
          if (response.success) {
            this.clearCart();
            this.$router.push({
              name: "payment",
              params: {
                checkout_url: response.checkout
              }
            });
          } else {
            this.$router.push("/error");
          }
        });
      } else {
        setTimeout(() => {
          this.$vuetify.goTo(".v-input.error--text");
        }, 500);
      }
    }
  },
  mounted() {
    if (this.notDeliverable) {
      this.delivery_types = this.delivery_types.filter(
        delitype => delitype.value === "PICKUP"
      );

      this.order.delivery_type = "PICKUP";
    }
  }
};
</script>
<style scoped></style>
