<template>
  <v-expand-transition>
    <v-card v-if="show">
      <v-card-text class="text-center text-lg-h5 text-subtitle-1 paws-notice">{{
        text
      }}</v-card-text>
    </v-card></v-expand-transition
  >
</template>
<script>
export default {
  props: {
    text: String,
    show: Boolean
  }
};
</script>
<style scoped>
.v-card .v-card__text.paws-notice {
  color: var(--v-secondary-base);
  background-color: var(--v-primary-base);
  font-weight: bold;
}
</style>
