<template>
  <v-container style="margin-top: 3rem;" @contextmenu.prevent.stop>
    <p>
      Welkom op onze webshop!<br /><br />
      Paws en Belly is niet zomaar de volgende hondenspeciaalzaak.<br />
      Wij hebben enkel producten van hoge kwaliteit die voldoen aan de
      voorwaarden voor een gezonde en vrolijke hond. Bij ons worden de producten
      getest en gescreend door onze vaste voedingsexpert, een gedragsdeskundige
      en een activiteitencoach.<br />
      Als de producten goed bevonden zijn door onze deskundigen dan pas komt hij
      tot bij onze experten met lange neus en veel haar
      <br /><br />
      Waarom dit zo belangrijk is om alles eerst goed te testen?<br />
      Gezonde voeding is belangrijk voor mensen maar zeker ook voor honden.<br />
      De laatste jaren wordt hierrond meer en meer wetenschappelijk onderzoek
      gevoerd. Hieruit blijkt dat ongezonde voeding voor fysieke klachten kan
      zorgen bij onze honden. Denk maar aan een slecht gebit, maag- en
      darmproblemen, slechte ontlasting, pijn aan de gewrichten, enz… Meer nog
      deze klachten kunnen ook samengaan met gedragsproblemen.<br /><br />
      Kortom wij zoeken de producten die het welzijn van de hond kunnen
      verbeteren.<br />
      We geven je daarnaast ook met veel plezier advies over welke voeding
      geschikt is voor jouw hond, welke speeltjes er interessant kunnen zijn en
      welke mat het comfortabelste zal liggen.<br />
      Bij ons stap je gegarandeerd met echte topkwaliteit naar buiten.
    </p></v-container
  >
</template>

<script>
export default {};
</script>

<style scoped></style>
