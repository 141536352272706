const state = {
  opened: false
};

const getters = {
  isOpened: (state) => state.opened
};

const actions = {};

const mutations = {
  toggleAlert: (state) => {
    state.opened = !state.opened;
  }
};

export default { state, getters, actions, mutations };
