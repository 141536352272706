<template>
  <v-container>
    <v-card>
      <v-card-title class="justify-center text-subtitle text-lg-h5">
        Wij verwerken momenteel uw bestelling.<br />
        U wordt zodadelijk doorgestuurd naar een betaalpagina. <br />
      </v-card-title>
      <v-card-text>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["checkout_url"],
  metaInfo() {
    return {
      title: "Betaling"
    };
  },
  methods: {
    browseToMollie: function(url) {
      if (url) {
        setTimeout(() => {
          window.location.href = url;
        }, 2000);
      } else {
        console.error("Provide url");
        this.$router.push("/error");
      }
    }
  },
  mounted() {
    this.browseToMollie(this.checkout_url);
  }
};
</script>

<style scoped>
.v-card .v-card__text {
  text-align: center;
}
</style>
