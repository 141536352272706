import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import store from "./../store";

// components
import CategoryList from "./../components/CategoryList.vue";
import ProductList from "./../components/products/ProductList.vue";
import ProductPage from "./../components/products/ProductPage.vue";
import Checkout from "./../components/Checkout.vue";
import OrderDetails from "./../components/OrderDetails.vue";
import PaymentProcessor from "./../components/PaymentProcessor.vue";
import ThankYou from "./../components/Thankyou.vue";
import NotFound from "./../components/NotFound.vue";
import ErrorPage from "./../components/Error.vue";
import TermsAndConditions from "./../components/TermsAndConditions.vue";
import DeliveryConditions from "./../components/DeliveryConditions.vue";
import AboutUs from "./../components/AboutUs.vue";

Vue.use(VueRouter);
Vue.use(Meta);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: CategoryList
    },
    {
      path: "/categories",
      name: "categories",
      component: CategoryList
    },
    {
      path: "/products",
      name: "product-list",
      component: ProductList
    },
    {
      path: "/product/:code",
      name: "product-info",
      component: ProductPage,
      // eslint-disable-next-line no-unused-vars
      beforeEnter: async (to, from, next) => {
        if (store.getters.allProducts.length === 0) {
          await store.dispatch("fetchProduct", to.params.code);
        }
        next();
      }
    },
    {
      path: "/checkout",
      name: "checkout",
      component: Checkout
    },
    {
      path: "/order_details",
      name: "orderDetails",
      component: OrderDetails
    },
    {
      path: "/payment",
      name: "payment",
      component: PaymentProcessor,
      props: true
    },
    {
      path: "/thankyou",
      name: "ThankYou",
      component: ThankYou
    },
    {
      path: "/error",
      name: "ErrorPage",
      component: ErrorPage
    },
    {
      path: "/terms_and_conditions",
      name: "TermsAndConditions",
      component: TermsAndConditions
    },
    {
      path: "/delivery_conditions",
      name: "DeliveryConditions",
      component: DeliveryConditions
    },
    {
      path: "/about_us",
      name: "AboutUs",
      component: AboutUs
    },
    // below this line should be the last entries
    {
      path: "/404",
      name: "NotFound",
      component: NotFound
    },
    {
      path: "*",
      redirect: "/404"
    }
  ],
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
