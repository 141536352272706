<template>
  <v-container>
    <v-row>
      <v-col>
        <the-alert
          :show="!info.category.deliverable"
          :text="`${info.category.name} is enkel beschikbaar voor afhaling`"
        ></the-alert
      ></v-col>
    </v-row>
    <v-row>
      <v-col :cols="`${isMobile ? '12' : '7'}`">
        <v-img
          :width="`${isMobile ? '100%' : '80%'}`"
          :height="`${isMobile ? '100%' : '80%'}`"
          contain
          @load="removePadding()"
          class="product-img"
          :src="
            `https://pawsandbelly.com${info.picture.location}/${info.picture.filename}`
          "
        >
        </v-img>
      </v-col>
      <v-col :cols="`${isMobile ? '12' : '5'}`">
        <div>
          <v-card outlined raised shaped>
            <v-chip
              color="red"
              x-large
              class="discount-chip font-weight-black white--text  accent-4"
              v-if="info.discount > 0"
            >
              - {{ info.discount }} %</v-chip
            >
            <v-card-title class="text-subtitle-1 text-md-h6">{{
              info.name
            }}</v-card-title>
            <v-card-text v-html="info.description"></v-card-text>
            <v-card-text>
              <v-simple-table dense>
                <template>
                  <tbody>
                    <tr>
                      <td
                        colspan="2"
                        v-if="selected.stock < ProductAmount"
                        class="text-subtitle-1 primary secondary--text rounded
                        "
                      >
                        De gevraagde hoeveelheid is niet voorradig
                      </td>
                    </tr>
                    <tr>
                      <td>Prijs</td>
                      <td v-if="info.discount === 0">
                        € {{ formatPrice(selected.unit_price) }}
                      </td>
                      <td v-else>
                        <span class="text-decoration-line-through"
                          >€ {{ formatPrice(selected.unit_price) }}</span
                        >
                        <span
                          class="font-weight-black red--text"
                          style="margin-left: 1em"
                          >€
                          {{
                            formatPrice(
                              selected.unit_price *
                                ((100 - info.discount) / 100)
                            )
                          }}</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>Hoeveelheid</td>
                      <td>
                        <v-container>
                          <v-item-group v-model="selected" mandatory>
                            <v-item
                              v-slot="{ active, toggle }"
                              v-for="option in info.options"
                              :key="option.id"
                              :value="option"
                            >
                              <v-btn
                                @click="toggle"
                                :color="`${active ? 'primary' : ''}`"
                                class="mr-6 mt-1"
                                small
                                >{{ option.description }}</v-btn
                              >
                            </v-item>
                          </v-item-group>
                        </v-container>
                      </td>
                    </tr>

                    <tr
                      v-for="variation in selected.variations"
                      :key="variation.type"
                    >
                      <td>{{ variation.type }}</td>
                      <td>
                        <v-container v-if="variation.type === 'COLOR'">
                          <v-item-group
                            class="color-container"
                            v-model="variant"
                            mandatory
                          >
                            <v-item
                              v-slot="{ active, toggle }"
                              v-for="val in variation.values"
                              :key="val.code"
                              :value="val"
                            >
                              <v-sheet
                                :color="val.hex"
                                height="30"
                                width="30"
                                @click="toggle"
                                :class="
                                  `rounded-circle ${
                                    active ? 'active-color' : ''
                                  }`
                                "
                              >
                              </v-sheet>
                            </v-item>
                          </v-item-group>
                        </v-container>
                      </td>
                    </tr>
                    <tr>
                      <td>Aantal</td>
                      <td>
                        <!-- <v-text-field
                          v-model="ProductAmount"
                          pattern="[0-9]*"
                          inputmode="numeric"
                        ></v-text-field> --><v-btn
                          icon
                          @click.stop.prevent="ProductAmount--"
                          color="secondary"
                          :disabled="ProductAmount === 1"
                        >
                          <v-icon>mdi-minus-box-outline</v-icon>
                        </v-btn>
                        {{ ProductAmount }}
                        <v-btn
                          icon
                          @click.stop.prevent="ProductAmount++"
                          color="secondary"
                          ><v-icon>mdi-plus-box-outline</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-divider class="mt-6 mx-4"></v-divider>
            <v-card-actions>
              <v-btn color="primary" raised block @click="addProductToCart">
                <v-icon>mdi-cart-outline</v-icon>
                <span class="ml-2">
                  Toevoegen aan winkelmand
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels multiple>
          <v-expansion-panel
            @click="expandedHandler"
            v-if="info.ingredients"
            class="accent"
          >
            <v-expansion-panel-header data-id="ingredients" class="info-header"
              >Samenstelling</v-expansion-panel-header
            >
            <v-expansion-panel-content
              id="ingredients"
              v-html="info.ingredients"
            ></v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            @click="expandedHandler"
            v-if="info.analytical_info"
            class="accent"
          >
            <v-expansion-panel-header data-id="analytical_info"
              >Analytische bestandsdelen</v-expansion-panel-header
            >
            <v-expansion-panel-content
              id="analytical_info"
              v-html="info.analytical_info"
            ></v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import utils from "./../../utils";
import TheAlert from "./../UI/TheAlert.vue";
export default {
  data() {
    return {
      selected: {},
      variant: {},
      ProductAmount: 1
    };
  },
  components: {
    TheAlert
  },
  computed: {
    ...mapGetters(["productInfo", "allProducts", "isMobile"]),
    info: function() {
      const product = this.$route.params.code;
      const prodInfo = this.productInfo(product);
      prodInfo.options = prodInfo.options.sort((a, b) => a.seq_nr - b.seq_nr);
      return prodInfo;
    }
  },
  methods: {
    ...mapActions(["addToCart", "fetchProducts", "fetchProduct"]),
    expandedHandler: function(e) {
      setTimeout(() => {
        this.$vuetify.goTo(`#${e.target.dataset.id}`);
      }, 500);
    },
    formatPrice: function(price) {
      return utils.formatNumber(price);
    },
    removePadding: function() {
      if (this.isMobile === false) {
        const responsive_sizer = document.querySelector(
          ".product-img .v-responsive__sizer"
        );
        responsive_sizer.style.cssText = "";
      }
    },
    addProductToCart: function() {
      for (let i = 1; i <= this.ProductAmount; i++) {
        this.addToCart({
          product: this.info,
          optionId: this.selected.id,
          variation: this.variant
        });
      }
    }
  },
  metaInfo() {
    return {
      title: utils.initcap(this.info.name)
    };
  },
  mounted() {
    this.selected = this.info.options.filter(
      (option) => option.default === true
    )[0];
  }
};
</script>
<style scoped>
.v-card,
.v-expansion-panels,
.v-expansion-panel:not(:first-child) {
  margin-top: 2rem;
}
.v-expansion-panel-content {
  display: block;
  padding: 0 1rem 0 1rem;
}
.btn-price {
  inset: -1rem auto calc(100% - 30px) calc(100% - 80px);
}
.v-expansion-panel-header {
  height: 5rem;
}
.color-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.color-container .color-box {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}
.active-color {
  border: 2px solid var(--v-primary-base);
  background-clip: content-box;
  padding: 2px;
}
.v-responsive__sizer {
  padding-bottom: 0% !important;
}
.v-item-group {
  flex-wrap: wrap;
}

.discount-chip {
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 9;
  font-size: 30px !important;
  box-shadow: 4px 3px 8px 1px grey;
}
</style>
