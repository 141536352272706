<template>
  <v-container fluid>
    <div class="d-flex justify-center">
      <v-card tile width="80%">
        <v-layout>
          <v-flex class="pa-10 pb-8 text-center">
            <v-img
              contain
              class="mx-auto"
              width="20%"
              src="../assets/error.svg"
            ></v-img
            ><v-card-text class="text-h5"
              >Er lijkt iets misgelopen te zijn. Wij lossen dit zo snel mogelijk
              op. <br />
              Probeer het later even opnieuw.
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="mx-auto"
                x-large
                color="primary"
                @click.prevent="$router.push('/')"
                >Terug naar homepagina</v-btn
              >
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-card>
    </div>
  </v-container>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: "Error"
    };
  }
};
</script>
<style scoped></style>
