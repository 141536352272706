<template>
  <v-row>
    <v-col cols="2" v-if="getFilters.length !== 0" class="hidden-md-and-down">
      <div>
        <v-navigation-drawer
          class="filter-drawer text-caption"
          fixed
          width="250px"
        >
          <filter-list></filter-list>
        </v-navigation-drawer></div
    ></v-col>
    <v-col>
      <v-container style="flex-basis: 100%" v-if="!productsAreDeliverable">
        <the-alert
          :show="!productsAreDeliverable"
          :text="`${category_name} is enkel beschikbaar voor afhaling`"
        ></the-alert>
      </v-container>
      <div class="d-flex justify-center flex-wrap align-start">
        <v-card
          v-for="product in filteredProducts(getFilteredValues)"
          :key="product.code"
          :max-width="cardWith"
          class="products"
          outlined
          raised
          shaped
          :style="isMobile ? 'margin-top: 20px;' : ''"
          :to="productLink(product.code)"
        >
          <v-chip
            color="red"
            x-large
            class="discount-chip font-weight-black white--text  accent-4"
            v-if="product.discount > 0"
          >
            - {{ product.discount }} %</v-chip
          >
          <v-img
            :src="
              `https://pawsandbelly.com${product.picture.location}/${product.picture.filename}`
            "
            contain
            :height="imgHeight"
          ></v-img>
          <v-card-subtitle class="product-title text-h6 font-weight-black">{{
            product.name
          }}</v-card-subtitle>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn
              @click.prevent="clickCart(product)"
              color="primary"
              fab
              elevation="3"
            >
              <v-icon>mdi-cart-outline</v-icon></v-btn
            >
            <v-spacer></v-spacer
            ><v-chip color="light_brown" label text-color="accent" class="pa-2">
              <v-icon left small>mdi-currency-eur</v-icon>
              {{
                `${formatPrice(defaultOption(product.code).unit_price)} -
                  ${defaultOption(product.code).description}`
              }}</v-chip
            >
            <v-spacer></v-spacer>
            <v-btn
              @click.prevent="product.expanded = !product.expanded"
              left
              color="secondary"
              icon
              x-small
            >
              <v-icon>{{
                product.expanded ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="product.expanded">
              <v-divider></v-divider>
              <v-card-text v-html="product.description"></v-card-text>
            </div>
          </v-expand-transition>
        </v-card></div
    ></v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import utils from "./../../utils";
import TheAlert from "./../UI/TheAlert.vue";
import FilterList from "./../products/FilterList.vue";

export default {
  components: {
    TheAlert,
    FilterList
  },
  metaInfo() {
    return {
      title: utils.initcap(this.$route.query.category.toLowerCase())
    };
  },
  computed: {
    ...mapGetters([
      "allProducts",
      "isLoaded",
      "defaultOption",
      "filteredProducts",
      "productsAreDeliverable",
      "getFilters",
      "getFilteredValues",
      "isMobile"
    ]),
    category_name() {
      return utils.initcap(this.$route.query.category);
    },
    cardWith() {
      switch (this.$vuetify.breakpoint.name) {
        default:
          return 250;
      }
    },
    imgHeight() {
      switch (this.$vuetify.breakpoint.name) {
        default:
          return 200;
      }
    }
  },
  methods: {
    ...mapActions(["addToCart", "fetchProducts", "fetchFilters"]),
    productLink: function(product) {
      return `/product/${product}`;
    },
    navigate: function(product) {
      this.$router.push({
        name: "product-info",
        params: {
          code: product
        }
      });
    },
    clickCart: function(product) {
      const defOption = this.defaultOption(product.code);
      if (defOption.variations.length > 0) {
        this.$router.push({
          name: "product-info",
          params: {
            code: product.code
          }
        });
      } else {
        this.addToCart({
          product: product,
          optionId: defOption.id
        });
      }
    },
    formatPrice(price) {
      return utils.formatNumber(price);
    }
  },
  mounted() {
    console.log("products mounted");
    const category = this.$route.query.category;
    this.fetchProducts(category).then(response => {
      if (response.success === false) {
        this.$router.push("/error");
      }
      this.fetchFilters(category);
    });
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      console.log("route changed");
      const category = to.query.category;
      this.fetchProducts(category).then(response => {
        if (response.success === false) {
          this.$router.push("/error");
        }
        this.fetchFilters(category);
      });
    }
  }
};
</script>
<style scoped>
.products {
  margin: 2% 0 0 2%;
}
.product-title {
  word-break: break-word;
  height: 5rem;
}
.price {
  background-color: gray;
  border-radius: 5px;
  padding: 5px;
  color: whitesmoke;
}
.v-navigation-drawer.filter-drawer {
  min-width: 250px;
  top: 140px !important;
  z-index: 1;
}

.discount-chip {
  position: absolute;
  right: -15px;
  top: -15px;
  z-index: 4;
  font-size: 30px !important;
  box-shadow: 4px 3px 8px 1px grey;
}
</style>
