<template>
  <v-container fluid>
    <div class="d-flex justify-center">
      <v-card tile width="80%">
        <v-layout>
          <v-flex class="pa-10 pb-8 text-center">
            <v-img
              contain
              class="mx-auto"
              :width="`${isMobile ? '100%' : '20%'}`"
              src="../assets/thank_you.svg"
            ></v-img
            ><v-card-text class="text-lg-h5 text-subtitle-1"
              >Bedankt voor uw bestelling.
            </v-card-text>
            <v-card-actions>
              <v-btn class="mx-auto" :x-large="!isMobile" color="primary" to="/"
                >Naar startpagina</v-btn
              >
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  methods: {
    ...mapActions(["clearCart"])
  },
  computed: {
    ...mapGetters(["isMobile"])
  },
  metaInfo() {
    return {
      title: "Bedankt"
    };
  },
  mounted() {
    this.clearCart();
  }
};
</script>
<style scoped></style>
