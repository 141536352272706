import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import router from "./router";
import VueGtag from "vue-gtag";

Vue.use(
  VueGtag,
  {
    config: {
      id: "G-5TDDB5V4CS"
    },
    enabled: false
  },
  router
);

Vue.config.productionTip = false;
store.dispatch("fetchConfigs");
store.dispatch("fetchCategories");

new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App)
}).$mount("#app");
