/**
 * All general functions
 *
 * @author Dominique Denie
 * @created 21-11-2021
 */
/**
 * Format a given number with leading zero and 2 zero after comma
 * @param {Number} number
 */
const formatNumber = (number, log = false) => {
  if (number) {
    if (log) {
      console.log("Format number");
      console.log(number);
    }
    return number.toFixed(2);
  }
};

/**
 * Initcap letters
 *
 * @param {String} string
 */
const initcap = (string) => {
  if (string) {
    string = string.toLowerCase();
    string = string.charAt(0).toUpperCase() + string.slice(1);
    return string.replaceAll("_", " ");
  } else {
    return null;
  }
};

export default {
  formatNumber,
  initcap
};
