import api from "./../../api";
const state = {
  categories: []
};

const getters = {
  getCategories: (state) => {
    return (parentCategory) => {
      console.log("get categories");
      if (state.categories.length > 0) {
        console.log(state.categories);
        if (parentCategory) {
          const retVal = state.categories.filter(
            (x) => x.code === parentCategory
          )[0].children;
          return retVal;
        } else {
          console.log("all categories");
          return state.categories;
        }
      }
    };
  },
  getMenu: (state) => state.categories
};

const mutations = {
  setCategories: (state, categories) => {
    state.categories = categories;
  }
};

const actions = {
  fetchCategories: async (context) => {
    console.log("fetch categories");
    const response = await api.fetchMenu();

    if (response.data.success) {
      context.commit("setCategories", response.data.menu);
      return { success: true, errors: [] };
    } else {
      return { success: false, errors: [response.data.message] };
    }
  }
};

export default { state, getters, mutations, actions };
