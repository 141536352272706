<template>
  <v-app-bar fixed color="accent" elevation="4" app flat>
    <!-- <div>
      <v-app-bar-nav-icon
        class=" primary v-btn--fab v-btn--has-bg v-btn--is-elevated hidden-lg-and-up"
        @click.stop="mobileDrawer = !mobileDrawer"
      ></v-app-bar-nav-icon>
    </div> -->
    <!-- <div style="margin-left: 96px; margin-top: -10px;"> -->
    <div :class="`${isMobile ? 'mobile-logo' : 'logo'}`">
      <v-img
        :src="require('../../assets/paws-logo.svg')"
        :width="imgWidth"
        transition="scale-transition"
        contain
        class="logo-img"
        @load="addMargin()"
        @click="goToHomePage()"
      ></v-img>
    </div>
    <div class="ml-auto">
      <shopping-popup></shopping-popup>
      <!-- <v-btn color="primary" fab> <v-icon>mdi-account-outline</v-icon></v-btn> -->
    </div>
    <template v-slot:extension>
      <v-tabs v-model="currentTab" right hide-slider>
        <template v-for="menuTab in getMenu">
          <v-tab
            :key="menuTab.id"
            class="hidden-md-and-down paws-tabs ml-auto"
            :to="tabLink(menuTab)"
            exact
            ripple
            v-if="!isFolder(menuTab)"
            >{{ menuTab.name }}</v-tab
          >
          <v-menu :key="menuTab.id" v-if="isFolder(menuTab)" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-tab
                v-bind="attrs"
                v-on="on"
                class="hidden-md-and-down paws-tabs ml-auto"
                >{{ menuTab.name }}
                <v-icon right>
                  mdi-menu-down
                </v-icon></v-tab
              >
            </template>
            <v-list>
              <v-list-item
                v-for="child in menuTab.children"
                :key="child.id"
                :to="tabLink(child)"
                exact
                >{{ child.name }}</v-list-item
              >
            </v-list>
          </v-menu>
        </template>
      </v-tabs>
    </template>
  </v-app-bar>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ShoppingPopup from "./ShoppingPopup.vue";

export default {
  components: {
    ShoppingPopup
  },
  data() {
    return {
      menuTabs: [],
      currentTab: null
    };
  },
  computed: {
    ...mapGetters(["cartIsEmpty", "allCategories", "isMobile", "getMenu"]),
    imgWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 125;
        case "sm":
          return 175;
        case "md":
          return 175;
        default:
          return 220;
      }
    },
    extensionHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return 25;
        case "xl":
          return 1;
        default:
          return 0;
      }
    }
  },
  methods: {
    ...mapActions(["fetchCategories"]),
    tabLink: function(tab) {
      if (tab.children) {
        return `/categories?parent=${tab.code}`;
      } else {
        return `/products?category=${tab.code}`;
      }
    },
    addMargin: function() {
      const logo =
        document.querySelector(".logo") ||
        document.querySelector(".mobile-logo");
      logo.style.cssText += `margin-top: ${this.isMobile ? "45" : "100"}px;`;
      logo.style.cssText += `${!this.isMobile ? "margin-left: -25px" : ""}`;

      this.addZIndex();
    },
    addZIndex: function() {
      const content = document.querySelector(".v-toolbar__content");
      const extension = document.querySelector(".v-toolbar__extension");

      content.style.cssText += `z-index: 900;`;
      extension.style.cssText += `z-index: 0;`;
      extension.style.cssText += `width: 90%;`;
      extension.classList.add("ml-auto");
    },
    goToHomePage: function() {
      if (this.$route.name !== "home") {
        this.$router.push("/");
      }
    },
    isFolder: function(menuItem) {
      if (menuItem.children) {
        if (menuItem.children.length !== 0) {
          return true;
        }
      }
      return false;
    }
  }
};
</script>
<style scoped>
.v-application--is-ltr .v-tabs--right > .v-tabs-bar .v-tab:last-child {
  margin-right: 100px;
}
.v-toolbar__content {
  z-index: 900 !important;
}
/* a.hidden-md-and-down.paws-tabs.ml-auto.v-tab--active.v-tab {
  color: var(--v-secondary-base);
  background-color: var(--v-primary-base);
  width: 100%;
  border-radius: 5px 5px 0px 0px;
} */
</style>
