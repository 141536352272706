<template>
  <v-container>
    <v-card
      elevation="0"
      class="mx-auto"
      v-if="allProducts.length > 0"
      :to="`/product/${promoProduct.code}`"
    >
      <v-card-title
        class="text-h6 text-md-h4 promo-title"
        v-html="promoProduct.description"
      ></v-card-title>
      <v-card-text>
        <v-img
          contain
          :width="`${isMobile ? '100%' : '20%'}`"
          class="mx-auto"
          :src="
            `https://pawsandbelly.com${promoProduct.picture.location}/${promoProduct.picture.filename}`
          "
        ></v-img
      ></v-card-text>
      <v-divider class="mt-6 mx-4"></v-divider>
      <v-card-actions>
        <v-btn color="primary" raised class="mx-auto" @click="addPromoToCart">
          <v-icon>mdi-cart-outline</v-icon>
          <span class="ml-2">
            Toevoegen aan winkelmand
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import utils from "../utils";
export default {
  computed: {
    ...mapGetters(["allProducts", "isMobile"]),
    promoProduct: function() {
      return this.allProducts[0];
    }
  },
  methods: {
    ...mapActions(["fetchProducts", "addToCart"]),
    formatPrice: function(price) {
      return utils.formatNumber(price);
    },
    addPromoToCart: function() {
      this.addToCart({
        product: this.promoProduct,
        optionId: this.promoProduct.options[0].id,
        variation: null
      });
    }
  },
  mounted() {
    this.fetchProducts("PROMO").then((response) => {
      if (response.success === false) {
        this.$router.push("/error");
      }
    });
  }
};
</script>

<style scoped>
.promo-title p {
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>
