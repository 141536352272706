import api from "../../api";

const state = {
  mobile: false,
  menu: []
};

const getters = {
  // eslint-disable-next-line no-unused-vars
  isMobile: (state) => state.mobile
};

const actions = {
  fetchMenu: async (context) => {
    const response = await api.fetchMenu();
    if (response.data.success) {
      context.commit("setMenu", response.data.menu);
      return { success: true, errors: [] };
    } else {
      return { success: false, errors: [response.data.message] };
    }
  }
};

const mutations = {
  setMobile: (state, mobile) => (state.mobile = mobile),
  setMenu: (state, menu) => (state.menu = menu)
};

export default {
  state,
  getters,
  actions,
  mutations
};
