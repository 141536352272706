<template>
  <v-container fluid>
    <div class="d-flex justify-center">
      <v-card tile width="80%">
        <v-layout>
          <v-flex class="pa-10 pb-8 text-center">
            <v-img
              contain
              class="mx-auto"
              width="20%"
              src="../assets/404_not_found.svg"
            ></v-img
            ><v-card-text class="text-h5"
              >Deze pagina lijkt niet te bestaan.
            </v-card-text>
            <v-card-actions>
              <v-btn class="mx-auto" x-large color="primary" to="/"
                >Naar startpagina</v-btn
              >
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-card>
    </div>
  </v-container>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: "404 not found"
    };
  }
};
</script>
<style scoped></style>
