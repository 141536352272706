import api from "../../api";

const state = {
  configs: {}
};

const getters = {
  getParameterVal: (state) => {
    return (parameter) => {
      return state.configs.parameters.filter(
        (elem) => elem.name === parameter
      )[0].value;
    };
  }
};

const actions = {
  fetchConfigs: (context) => {
    return new Promise((resolve) => {
      api
        .fetchConfigs()
        .then((response) => {
          if (response.data.success) {
            delete response.data.success;
            context.commit("setConfigs", response.data);
            resolve({ success: true, errors: [] });
          } else {
            resolve({ success: false, errors: [response.data.message] });
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          resolve({ success: false, errors: [error] });
        });
    });
  }
};

const mutations = {
  setConfigs: (state, configs) => {
    state.configs = configs;
  }
};

export default { state, getters, actions, mutations };
