import api from "../../api";

const state = {
  filters: [],
  filteredValues: []
};

const getters = {
  getFilters: (state) => {
    return state.filters;
  },
  getFilteredValues: (state) => state.filteredValues
};

const actions = {
  fetchFilters: (context, category) => {
    return new Promise((resolve) => {
      api
        .fetchFilters(category)
        .then((response) => {
          if (response.data.success) {
            context.commit("setFilters", response.data.filters);
            resolve({ success: true, errors: [] });
          } else {
            resolve({ success: false, errors: [response.data.message] });
          }
        })
        .catch((error) => {
          resolve({ success: false, errors: [error] });
        });
    });
  },
  composeFilterArr: function(context, filter) {
    let foundInArray = false;

    context.state.filteredValues = context.state.filteredValues.map((v) => {
      if (v.code === filter.code) {
        foundInArray = true;
        return filter;
      }
    });

    if (!foundInArray) {
      context.commit("addToFiltered", filter);
    }
  }
};

const mutations = {
  setFilters: (state, filters) => (state.filters = filters),
  addToFiltered: (state, filter) => {
    state.filteredValues.push(filter);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
