<template>
  <v-container>
    <v-row class="mt-6">
      <v-col :cols="`${isMobile ? '12' : '8'}`">
        <v-card v-if="cartContents.length !== 0">
          <v-card-title>Winkelmand</v-card-title>
          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr
                  v-for="(item, index) in cartContents"
                  :key="`cart-${index}`"
                >
                  <td v-if="!isMobile" class="product-img">
                    <v-img
                      width="5rem"
                      :src="
                        `https://pawsandbelly.com${item.picture.location}/${item.picture.filename}`
                      "
                    ></v-img>
                  </td>
                  <td :class="isMobile ? '' : 'product-descr'">
                    {{ item.name }} <br />
                    ( {{ item.option.description }}
                    {{
                      `${
                        item.option.variation
                          ? " - " + item.option.variation.name ||
                            item.option.variation
                          : ""
                      }`
                    }}
                    )
                    <div
                      class="text-subtitle-1 primary secondary--text rounded text-center"
                      v-if="item.option.stock < item.amount"
                    >
                      De gevraagde hoeveelheid is niet voorradig.
                    </div>
                  </td>
                  <td style="width: 8rem;">
                    <v-btn
                      icon
                      @click.stop="decreaseAmount(index)"
                      color="secondary"
                    >
                      <v-icon>
                        {{
                          item.amount === 1
                            ? "mdi-delete-outline"
                            : "mdi-minus-box-outline"
                        }}
                      </v-icon>
                    </v-btn>
                    {{ item.amount }}
                    <v-btn
                      icon
                      @click.stop.prevent="increaseAmount(index)"
                      color="secondary"
                      ><v-icon>mdi-plus-box-outline</v-icon></v-btn
                    >
                  </td>
                  <td class="text-right">
                    €
                    {{
                      itemPrice(
                        item.option.unit_price * ((100 - item.discount) / 100),
                        item.amount
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title>Winkelmand</v-card-title>
          <v-card-text class="text-h5"
            >Het lijkt er op dat uw winkelmand leeg is</v-card-text
          >
        </v-card>
      </v-col>
      <v-col
        :cols="`${isMobile ? '12' : '4'}`"
        :style="`${isMobile ? 'order: 1;' : 'position: fixed; right: 0;'}`"
      >
        <v-card>
          <v-card-text v-if="cartContents.length !== 0">
            <v-simple-table>
              <tbody>
                <tr>
                  <td class="text-left">Totaalbedrag</td>
                  <td class="text-right font-weight-bold">€ {{ subTotal }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn
                  block
                  color="primary"
                  to="/order_details"
                  v-if="cartContents.length !== 0"
                >
                  Verder naar bestellen
                </v-btn>
              </v-col>
              <v-col>
                <v-btn block @click="$router.go(-1)">Verder winkelen</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import utils from "./../utils";

export default {
  data() {
    return {
      paymentMethods: []
    };
  },
  computed: {
    ...mapGetters([
      "cartCount",
      "cartContents",
      "subTotal",
      "isMobile",
      "deliveryPrice"
    ]),
    outOfStock: function() {
      let outStock = false;
      this.cartContents.forEach((content) => {
        if (content.option.stock <= content.amount) {
          outStock = true;
          return;
        }
      });

      return outStock;
    }
  },
  metaInfo() {
    return {
      title: "Winkelmand"
    };
  },
  methods: {
    ...mapActions(["removeFromCart", "increaseAmount", "decreaseAmount"]),
    itemPrice: function(unit_price, amount) {
      return utils.formatNumber(unit_price * amount);
    },
    ...utils
  }
};
</script>

<style scoped>
.price {
  text-align: right;
}

.product-img {
  width: 140px;
}

.product-descr {
  width: 650px;
}
table thead tr th {
  text-align: center;
}
</style>
