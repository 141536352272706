import axios from "axios";

const ROOT_URL =
  "https://rxzwovgxaqhm6at-dbdomiprod.adb.eu-frankfurt-1.oraclecloudapps.com/ords/own_apps/webshop";

export default {
  fetchProducts: category => {
    return axios.get(`${ROOT_URL}/products`, {
      params: { category }
    });
  },
  fetchProduct: product_code => {
    return axios.get(`${ROOT_URL}/product`, {
      params: { product_cd: product_code }
    });
  },
  fetchCategories: parentCategory => {
    return axios.get(`${ROOT_URL}/categories`, {
      params: { category_cd: parentCategory }
    });
  },
  postOrder: (orderInfo, productInfo) => {
    return new Promise(resolve => {
      try {
        //compose the order object
        const order = {
          delivery_type: orderInfo.delivery_type,
          newsletter: orderInfo.newsletter,
          email: orderInfo.email,
          telephone_nr: orderInfo.telephone_nr,
          invoice: orderInfo.invoice,
          vat_nr: orderInfo.vat_nr,
          company_name: orderInfo.company_name,
          addresses: [],
          products: []
        };

        // if invoice is not selected clear out vat & company name
        if (!order.invoice) {
          order.vat_nr = "";
          order.company_name = "";
        }

        // add addresses
        if (!orderInfo.delivery_info) {
          orderInfo.billing_addr.type = "BOTH";
        } else {
          orderInfo.delivery_addr.type = "DELIVERY";
          orderInfo.billing_addr.type = "BILLING";

          order.addresses.push(orderInfo.delivery_addr);
        }

        order.addresses.push(orderInfo.billing_addr);

        // Add products
        productInfo.forEach(product => {
          const oProduct = {
            option_id: product.option.id,
            amount: product.amount
          };

          if (product.option.variation) {
            oProduct.variation =
              product.option.variation.code || product.option.variation;
          }
          order.products.push(oProduct);
        });

        axios.post(`${ROOT_URL}/order`, order).then(response => {
          if (response.data.success) {
            resolve({
              success: true,
              checkout: response.data.checkout,
              errors: []
            });
          } else {
            resolve({ success: false, errors: [response.data.message] });
          }
        });
      } catch (error) {
        console.error(error);
        resolve({ success: false, errors: [error] });
      }
    });
  },
  fetchFilters: category => {
    return axios.get(`${ROOT_URL}/filters`, {
      params: {
        category_cd: category
      }
    });
  },
  fetchConfigs: () => {
    return axios.get(`${ROOT_URL}/configs`);
  },
  fetchMenu: () => {
    return axios.get(`${ROOT_URL}/menu`);
  }
};
